// IMPORTS
@import (reference) "../settings.less";

.greeting-section {
    .grid();
    grid-template-columns: 950px minmax(0, 1fr);
    gap: 24px;

    &:has(.mainpage-greeting:first-child) {
        display: flex !important;

        .mainpage-greeting {
            align-items: center;
            overflow-y: initial;
            aspect-ratio: initial;
            height: initial;
            width: 100%;

            > * {
                text-align: center;
                max-width: 900px;
                overflow-y: initial;
            }
        }
    }
}

.main-banner {
    aspect-ratio: ~"950/432";

    .banner-text-wrapper {
        text-align: initial;
        padding: 16px 47px 16px 32px;
        right: initial;
        left: 0;
        bottom: 0;
        margin: 40px;
        width: auto;
    }
}

.mainpage-greeting {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 20px 40px 40px;
    height: 100%;
    max-height: 100%;
    background: @main-color;
    color: white;
    overflow-y: auto;
    aspect-ratio: ~"466/432";

    .greeting-headline {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.08em;
        font-size: 20px;
        color: inherit;
    }

    .greeting-body {
        max-height: 100%;
        overflow-y: auto;
        padding-right: 18px;
        .scrollbar();
    }
}

// 2 MAIN BANNERS
.main-banner,
.second-banner {
    width: 100%;
    background-color: darken(@light-bg, 7%);
    position: relative;

    .swiper-slide {
        overflow: hidden;
    }

    .banner-img-wrapper {
        height: 100%;
        width: 100%;
    }

    img {
        .img-crop();
        opacity: 0;
        transition: opacity .2s ease-in-out;

        &[src] {
            opacity: 1;
        }
    }
}

.greeting-section + .second-section {
    margin-top: -57px;
}

// SECOND SECTION
.second-section {
    .grid();
    grid-template-columns: minmax(0, 1fr) 700px;
    gap: 24px;
}

.second-banner {
    aspect-ratio: ~"700/343";
    overflow: hidden; // for triangle decal

    &:before {
        .triangle-decal();
    }

    .banner-text-wrapper {
        padding: 16px 50px;
        bottom: 32px;
    }
}

.mainpage-products-slider {
    display: flex;
    flex-direction: column;
    height: 100%;
    aspect-ratio: ~"716/343";
    border: 1px solid @border-color;
    width: 100%;
    overflow: hidden;
    padding: 27px 32px;
    gap: 26px;

    .promo-header {
        height: 40px;
    }

    .swiper-buttons-wrapper {
        display: flex;
        gap: 4px;

        .products-slider-button {
            position: static;
            margin: 0 !important;
        }
    }

    .swiper {
        width: 100%;
        flex: 1;
    }
}


.product-single-promoted {
    .grid();
    grid-template-columns: 270px minmax(0, 1fr);
    align-items: start;
    gap: 24px;
    align-content: center;

    figure {
        width: 100%;
        aspect-ratio: ~"270/180";
        flex-shrink: 0;
        position: relative;
    }

    .promo-badge {
        left: 0;
        right: initial;
    }
}

.product-single-promoted-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    .product-name {
        text-align: start;
        font-weight: 600;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        margin-top: 12px;
        color: @font-color;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-price {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 32px;

        del {
            top: 0;
        }
    }
}

.promo-header {
    display: flex;
    gap: 40px;
    align-items: center;

    .headline {
        margin-bottom: 0 !important;
    }

    a.main-page-product-all {
        text-transform: uppercase;
        font-weight: 700;
        color: @header-color;
        font-size: 10px;
        letter-spacing: 0.08em;
        background: @light-bg;
        padding: 8px 16px;
        margin-left: auto;

        &:hover {
            background: @main-color;
            color: white;
        }
    }
}

// LAZY CLS
.mainpage-promo {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.page-wide-products {
    grid-template-columns: repeat(var(--prodSwiperCols), minmax(0, 1fr));
    gap: var(--prodGapVert) 10px;
}

.grid-banners-wrapper {
    .grid();
    grid-template-columns: repeat(4, minmax(0,1fr));
    gap: 20px;
}

.grid-banner {
    width: 100%;
    aspect-ratio: ~"345/200";
    overflow: hidden;
    
    .banner-text-wrapper {
        display: none;
    }
}

.blog-banners-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.blog-banners-wrapper {
    .grid();
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 30px;
}

.blog-banner {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 24px;
    grid-template-rows: auto auto 1fr;


    figure {
        width: 100%;
        aspect-ratio: ~"460/230";
    }
}